@media print {
	.no-print {
		display: none !important;
	}

	.no-break {
		break-inside: avoid;
	}

	.report-header {
		position: relative;
	}
}

.highlighted {
	background: rgba(255, 255, 0, 0.4);
	padding-inline: 0.5rem;
	width: fit-content;
}

.report-header {
	position: sticky;
	top: 0;
}
