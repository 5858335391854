/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
	/** primary **/
	--ion-color-primary: #4a5568;
	--ion-color-primary-rgb: 74, 85, 104;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #414b5c;
	--ion-color-primary-tint: #5c6677;

	/** secondary **/
	--ion-color-secondary: #edf2f7;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #767880;
	--ion-color-secondary-tint: #b3b5be;

	/** tertiary **/
	--ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	/** success **/
	--ion-color-success: #4ba046;
	--ion-color-success-rgb: 75, 170, 60;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-dark: #4a5568;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	--ion-background-color: #f9fbfd;
	--ion-toolbar-background: #ffffff;
}

/** Custom colors **/
:root {
	--ion-color-gray100: #edf2f7;
	--ion-color-gray200: #e2e8f0;

	--ion-color-gray500: #718096;
	--ion-color-gray500-rgb: 113, 128, 150;
	--ion-color-gray500-contrast: #ffffff;
	--ion-color-gray500-contrast-rgb: 255, 255, 255;
	--ion-color-gray500-shade: #637184;
	--ion-color-gray500-tint: #7f8da1;

	--ion-color-gray600: #4a5568;
	--ion-color-gray600-rgb: 74, 85, 104;
	--ion-color-gray600-contrast: #ffffff;
	--ion-color-gray600-contrast-rgb: 255, 255, 255;
	--ion-color-gray600-shade: #414b5c;
	--ion-color-gray600-tint: #5c6677;
}

.ion-color-gray500 {
	--ion-color-base: var(--ion-color-gray500);
	--ion-color-base-rgb: var(--ion-color-gray500-rgb);
	--ion-color-contrast: var(--ion-color-gray500-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray500-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray500-shade);
	--ion-color-tint: var(--ion-color-gray500-tint);
}

.ion-color-gray600 {
	--ion-color-base: var(--ion-color-gray600);
	--ion-color-base-rgb: var(--ion-color-gray600-rgb);
	--ion-color-contrast: var(--ion-color-gray600-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray600-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray600-shade);
	--ion-color-tint: var(--ion-color-gray600-tint);
}

@media (prefers-color-scheme: dark) {
	/*
   * Dark Colors
   * -------------------------------------------
   */

	body {
		/** primary **/
		--ion-color-primary: #4a5568;
		--ion-color-primary-rgb: 74, 85, 104;
		--ion-color-primary-contrast: #ffffff;
		--ion-color-primary-contrast-rgb: 255, 255, 255;
		--ion-color-primary-shade: #414b5c;
		--ion-color-primary-tint: #5c6677;

		/** secondary **/
		--ion-color-secondary: #edf2f7;
		--ion-color-secondary-rgb: 61, 194, 255;
		--ion-color-secondary-contrast: #000000;
		--ion-color-secondary-contrast-rgb: 255, 255, 255;
		--ion-color-secondary-shade: #767880;
		--ion-color-secondary-tint: #b3b5be;

		/** tertiary **/
		--ion-color-tertiary: #5260ff;
		--ion-color-tertiary-rgb: 82, 96, 255;
		--ion-color-tertiary-contrast: #ffffff;
		--ion-color-tertiary-contrast-rgb: 255, 255, 255;
		--ion-color-tertiary-shade: #4854e0;
		--ion-color-tertiary-tint: #6370ff;

		/** success **/
		--ion-color-success: #4ba046;
		--ion-color-success-rgb: 75, 170, 60;
		--ion-color-success-contrast: #ffffff;
		--ion-color-success-contrast-rgb: 255, 255, 255;
		--ion-color-success-shade: #28ba62;
		--ion-color-success-tint: #42d77d;

		/** warning **/
		--ion-color-warning: #ffc409;
		--ion-color-warning-rgb: 255, 196, 9;
		--ion-color-warning-contrast: #000000;
		--ion-color-warning-contrast-rgb: 0, 0, 0;
		--ion-color-warning-shade: #e0ac08;
		--ion-color-warning-tint: #ffca22;

		/** danger **/
		--ion-color-danger: #eb445a;
		--ion-color-danger-rgb: 235, 68, 90;
		--ion-color-danger-contrast: #ffffff;
		--ion-color-danger-contrast-rgb: 255, 255, 255;
		--ion-color-danger-shade: #cf3c4f;
		--ion-color-danger-tint: #ed576b;

		/** dark **/
		--ion-color-dark: #4a5568;
		--ion-color-dark-rgb: 34, 36, 40;
		--ion-color-dark-contrast: #ffffff;
		--ion-color-dark-contrast-rgb: 255, 255, 255;
		--ion-color-dark-shade: #1e2023;
		--ion-color-dark-tint: #383a3e;

		/** medium **/
		--ion-color-medium: #92949c;
		--ion-color-medium-rgb: 146, 148, 156;
		--ion-color-medium-contrast: #ffffff;
		--ion-color-medium-contrast-rgb: 255, 255, 255;
		--ion-color-medium-shade: #808289;
		--ion-color-medium-tint: #9d9fa6;

		/** light **/
		--ion-color-light: #f4f5f8;
		--ion-color-light-rgb: 244, 245, 248;
		--ion-color-light-contrast: #000000;
		--ion-color-light-contrast-rgb: 0, 0, 0;
		--ion-color-light-shade: #d7d8da;
		--ion-color-light-tint: #f5f6f9;

		--ion-background-color: #f9fbfd;
		--ion-toolbar-background: #ffffff;

		/* Custom Colors */
		--ion-color-gray200: #e2e8f0;

		--ion-color-gray500: #718096;
		--ion-color-gray500-rgb: 113, 128, 150;
		--ion-color-gray500-contrast: #ffffff;
		--ion-color-gray500-contrast-rgb: 255, 255, 255;
		--ion-color-gray500-shade: #637184;
		--ion-color-gray500-tint: #7f8da1;

		--ion-color-gray600: #4a5568;
		--ion-color-gray600-rgb: 74, 85, 104;
		--ion-color-gray600-contrast: #ffffff;
		--ion-color-gray600-contrast-rgb: 255, 255, 255;
		--ion-color-gray600-shade: #414b5c;
		--ion-color-gray600-tint: #5c6677;
	}
}
