.cameraButton {
	background-color: var(--ion-color-gray100);
	grid-column: span 1;
	min-height: 5.5rem;
	height: 100%;
	margin: 0;
	border: 1px dashed var(--ion-color-gray500);
	border-radius: 10px;
	box-shadow: none;
	text-align: center;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cameraButton input[type="file"] {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: transparent;
}

.cameraButton input[type="file"]::file-selector-button {
	border: none;
	padding: 0.2em 0.4em;
	border-radius: 0.2em;
	background-color: transparent;
	transition: 1s;
	color: transparent;
	cursor: pointer;
	height: 100%;
	width: 100%;
}
