.gallery {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	margin-inline: unset;
	margin: 1rem 0;
	gap: 1rem;
}

@media (min-width: 420px) {
	.gallery {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@media (min-width: 600px) {
	.gallery {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}
